import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AlphaListPage from '../../components/AlphaListPage';

const Definitions = () => {
  const { copy, items } = useStaticQuery(graphql`
    query getDefinitions {
      copy: datoCmsDefinition {
        title
        intro
        seoMetaTags {
          tags
        }
      }
      items: allDatoCmsConcept(sort: { order: ASC, fields: concept }) {
        edges {
          node {
            title: concept
            body: explanation
          }
        }
      }
    }
  `);

  return <AlphaListPage copy={copy} items={items} />;
};

export default Definitions;
